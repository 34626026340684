<template>
  <div class="wrapper">
    <div v-if="list.length" class="overflow-auto">
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>№</th>
            <th>№ СЦ</th>
            <th>СЦ</th>
            <th>создан</th>
            <th>инструмент</th>
            <th>группа</th>
            <th>S№</th>
            <th>дата продажи</th>
            <th>дата принятия в ремонт</th>
            <th>дефект</th>
            <th>продавец</th>
          </tr>
        </thead>
        <tr
          v-for="(item, idx) in list"
          :key="idx"
          @click="goRepair(item.remont_id)"
        >
          <td>{{ idx + 1 }}</td>
          <td>{{ item.repair_number }}</td>
          <td>{{ item.receiptNumber }}</td>
          <td>{{ item.user }}</td>
          <td>{{ item.date_add }}</td>
          <td>{{ item.name_instr }}</td>
          <td>{{ item.serialNumberGroup }}</td>
          <td>{{ item.serialNumber }}</td>
          <td>{{ item.dateSale }}</td>
          <td>{{ item.dateRepair }}</td>
          <td>{{ item.defect }}</td>
          <td>{{ item.seller }}</td>
        </tr>
      </table>
    </div>
    <div v-else class="text-center">избранные ремонты отсутствуют</div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapActions } from "vuex";
export default {
  name: "FavoritesRepairs",
  data: () => ({
    list: []
  }),
  methods: {
    ...mapActions(["ADDPATH"]),
    get_data() {
      HTTPGET({
        url: "/repairs/favorites_list.php"
      }).then(response => (this.list = response.data.list));
    },
    goRepair(id) {
      this.$router.push(`/repairs/repair/${id}?path=избранное`);
    }
  },
  mounted() {
    this.ADDPATH("избранное"), this.get_data();
  }
};
</script>
<style scoped>
.wrapper {
  margin: 10px;
}
table {
  font-size: 0.7em;
}
th {
  text-align: center;
}
tr {
  cursor: pointer;
}
</style>
